
const Utilization = () => (
        <section className="sec-util" id="buy"><img className="sec-bg bg-right d-none d-xl-block" src="./pics/bg/bg-lg-right-1.svg" alt="" />
          <div className="container">
            <div className="row"> 
              <div className="col-10 col-xl-10">
              	<h2>NIFTSY Token Utilization</h2>
                <div className="distrib__row"> 
                  <div className="row">
                    <div className="col-12 col-lg-3">1. DAO Envelop member</div>
                    <div className="col">The owner of the NIFTSY token is a member of DAO Envelop. Membership in the DAO gives you the opportunity to participate in the microDAO.</div>
                  </div>
                </div>
                <div className="distrib__row"> 
                  <div className="row">
                    <div className="col-12 col-lg-3">2. Membership and queue in microDAO</div>
                    <div className="col">Membership in the micro-DAO is available only to NIFTSY token holders. Membership implies participation in the distribution of the operating inflow of the project.</div>
                  </div>
                </div>
                <div className="distrib__row"> 
                  <div className="row">
                    <div className="col-12 col-lg-3">3. Affiliation with the protocol for B2B partners</div>
                    <div className="col">Partners can receive multilateral support from DAO Envelop members by signing an accession agreement under which they undertake to transfer NIFTSY in a form of wNFT blocked for 5 years to a wallet for a future bounty fund.</div>
                  </div>
                </div>
                <div className="distrib__row"> 
                  <div className="row">
                    <div className="col-12 col-lg-3">4. Utilization via B2B/B2C Envelop products</div>
                    <div className="col">The NIFTSY token is required to perform operational tasks, subscribe to DAO Envelop products / services, such as for:
                    	<ul>
		                    <li>SAFT wNFT subscriptions for startups/funds or private users</li>
		                    <li>connecting Oracle for quick analysis of the contents of the collateral</li>
		                    <li>other products</li></ul></div>
                  </div>
                </div>
                <div className="distrib__row"> 
                  <div className="row">
                    <div className="col-12 col-lg-3">5. Utilization via partners products / ecosystem</div>
                    <div className="col">The NIFTSY token can be disposed of through partnerships, for example:
                    	<ul>
		                    <li>as one of several skill tokens</li>
		                    <li>stake/subscription to access the partner's service</li>
		                    <li>payment by the partner himself (B2B utilization)</li></ul></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  );

export default Utilization;