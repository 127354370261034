import React from 'react';
import DistribRow from './distribrow.component';
import config from '../config.json';

class Distrib extends React.Component {

  constructor() {
    super();
    this.state = {
      	rows: [],
        isLoaded: false,
        total: 0,
        emitted: 0,
        locked: 0,
        longterm: 0
    };
  }

  componentDidMount() {
    try {
      fetch(config.apiURL + "/dao/info")
        .then((res) => res.json())
        .then((json) => {
            if ('error' in json) {
              throw new Error( json["error"] );
            }
            else {
              this.setState({
                  rows: json,
                  isLoaded: true
              });
              this.countTotal();
            }
        })
        .catch(error => {
          console.log(error);
        });
    } catch(err) {
      console.log(err);
    }
  }

  countTotal = () => {
    if (this.state.rows.length) {
      this.state.rows.map(({emitted, locked, longterm}) => {
        this.setState({
          emitted: this.state.emitted + emitted,
          locked: this.state.locked + locked,
          longterm: this.state.longterm + longterm
        });
        return true;
      });
      this.setState({
          total: this.state.emitted + this.state.locked
      });
    }
  }

  numberWithSpaces = function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d)\.(\d))/g, " ");
  }

  monthFromDate = function(m) {
    const date = new Date(m);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames[ date.getMonth() ] + " " + date.getFullYear();
  }

  getCurrentDate = function(sep='') {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${sep}${month<10?`0${month}`:`${month}`}${sep}${date}`
  }

  render() {

    const { isLoaded, rows } = this.state;

    return (
        <section className="sec-distrib" id="distrib"><img className="sec-bg bg-left d-none d-lg-block" src="./pics/bg/bg-lg-left-1.svg" alt="" />
          <div className="container">
            <div className="row"> 
              <div className="col-12 col-xl-10 offset-xl-2">
                <h2>Token distribution by Blockchains </h2>
                {
                  !isLoaded ? (
                    <p>Loading..</p>
                  ) : (
                    <>
                      <div className="distrib__row header d-none d-lg-block"> 
                        <div className="row">
                          <div className="col-12 col-lg-3"></div>
                          <div className="col col-right"> <span className="legend">Total supply</span><span className="sum">{ this.numberWithSpaces( this.state.total.toFixed(8) ) }</span></div>
                          <div className="col col-right"> <span className="legend">Circulating</span><b className="sum">{ this.numberWithSpaces( this.state.emitted.toFixed(8) ) }</b></div>
                          <div className="col col-right"> <span className="legend">Non-circulating</span><span className="sum">{ this.numberWithSpaces( this.state.locked.toFixed(8) ) }</span></div>
                        </div>
                      </div>
                      { (rows.length) ? rows
                          .map(({id, ...otherItemProps}) => (
                            <DistribRow key={id} id={id} {...otherItemProps} numberWithSpaces={this.numberWithSpaces} getCurrentDate={this.getCurrentDate} monthFromDate={this.monthFromDate} />
                          )) : 'no data currently' }
                    </>
                  )
                }
              </div>
            </div>
            <div className="row"> 
              <div className="col-12 col-xl-10 offset-xl-2 mt-5">
                <p>Starting from its creation in October 2021, DAO Envelop has been striving towards greater decentralization. In order to standardize this process not only across the three main elements - Protocol, Oracle, and Index, but also their connecting element - NIFTSY token, a simple emission model has been proposed:</p>
              <ol>
                <li className="mb-3">If the token can be minted on the certain network without an official bridge (such as https://wallet.polygon.technology/), emission occurs via a standardized smart contract with a total token supply of 500,000,000.00;</li>
                <li className="mb-3">After that, an initial token burn occurs according to the following formula: Ethereum network - 365,000,000.00 NIFTSY (including Polygon bridge), BSC - 40,000,000.00 NIFTSY, Arbitrum - 11,111,111.11 NIFTSY, and subsequently CHAIN_X-(CHAIN_X*12%), meaning the emission for each new blockchain/DAG is reduced by 12%. Thus, for 5th (including Polygon bridge) blockchain, the emission will be 9,777,777.78 in total, for 6th - 8,604,444.44, and for 100th - 56.63 NIFTSY;</li>
                <li className="mb-3">As a result, the total circulation of tokens across all blockchains/DAGs cannot exceed 500,000,000.00 NIFTSY;</li>
                <li>The primary blockchain tokens are: Ethereum, Polygon (via bridge), BSC, and Arbitrum. Subsequent networks are approved by DAO vote, taking into account the development team's capabilities.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

export default Distrib;
