import { ReactComponent as Daoimg } from '../pics/dao-img.svg';

const ErrorPage = () => (
        <section className="sec-microdaointro" id="microdaointro">
          <div className="container">
            <div className="intro__img"><Daoimg alt="Envelop DAO" /></div>
            <div className="intro__text">
              <h1>Error</h1>
              <p className="h1_sub">PAGE NOT FOUND</p>
              <p>We couldn't find the page you're looking for.</p>
              <div class="row"><div class="col-12 col-sm-6 mb-4 mb-sm-0"><a class="btn" href="https://dao.envelop.is/" target="_blank">DAO mainpage</a></div><div class="col-12 col-sm-4"><a class="btn btn-404" href="https://envelop.is/" target="_blank">Envelop</a></div></div>
            </div>
          </div>
        </section>
);

export default ErrorPage;