import { Link } from 'react-router-dom';
import { ReactComponent as Daoimg } from '../pics/dao-img.svg';

const MicroDao = () => (
        <main className="s-main">
          <MicrodaoIntro />
        </main>
);

function MicrodaoIntro() {
  return (
        <section className="sec-microdaointro" id="microdaointro">
          <div className="container">
            <div className="intro__img"><Daoimg alt="Envelop DAO" /></div>
            <div className="intro__text">
              <h1><span className="text-grad">MicroDAOs</span></h1>
              <p>Micro-DAO is a project management system created on the basis of DAO Envelop to operate with both internal and partners’ projects.</p>
              <p>Micro-DAO targets the following tasks:</p>
              <ol>
                <li>Development of DAO Envelop ecosystem in directions of Protocol, Oracle, and as well as their combinations;</li>
                <li>NIFTSY Token functionality increase;</li>
                <li>Smart voting system for DAO members as contribution in the new micro-DAO to acquire voting power.</li>
              </ol>
              <p>This approach solves the following  problems:</p>
              <ol>
                <li>There is no intermediate tool such as management token, which in most cases plays a purely speculative role;</li>
                <li>There is an opportunity to participate in a variety of projects to improve own portfolio diversification and reduce the risks for p2p projects;</li>
                <li>Incremental involvement in all four DAO Envelop products: Protocol, Oracle, Index and Token.</li>
              </ol>
              <p>The queue for participation in Micro-DAO could be assigned by transferring funds to the farming pool refilled with the rewards from other DAO Envelop participants, who can participate in various Micro-DAOs. Any wNFT with locked liquidity can be traded on the secondary market, for example, using SCOTCH micro-DAO.</p>
              <Link to={"/microdao/new"} className="btn btn-outline">
                + Create MicroDAO
              </Link>
            </div>
            <div className="row"> 
              <div className="col-12 col-sm-6 mb-4 mb-sm-0">
              </div>
            </div>
          </div>

        </section>
  );
}

function MicrodaoList() {
  return (
        <section className="sec-microdaolist" id="microdaolist">
          <div className="container">
            <div class="row">
              <div class="col-12 col-xl-10">
                  <h2>Active microDAOs</h2>
                  <div class="list__row">
                    <div class="row">
                      <div class="col-12 col-lg-4">
                      <Link to={"/microdao/scotch"} className="list-item">
                        SCOTCH microDAO
                      </Link>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
  );
}

export default MicroDao;