const INITIAL_STATE = {
	path: true
}

const stateReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_PATH':
			return {
				...state,
				path: action.payload
			}
		default:
			return state;
	}

}

export default stateReducer;