import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './scss/styles.scss';
import ScrollToTop from './helpers/ScrollToTop';
import ErrorPage from './components/error.component';
import Header from './components/header.component';
import Footer from './components/footer.component';
import MainPage from './components/mainpage.component';
import MicroDao from './components/microdao.component';
import MicrodaoForm from './components/microdaoform.component';
import MicrodaoCard from './components/microdaocard.component';

class App extends React.Component {

  constructor() {
    super();
    this.state = {
    };
  }

  componentDidMount() {
    // const script = document.createElement("script");
    // script.src = "./js/script.js";
    // script.async = true;
    // script.onload = () => this.scriptLoaded();
    // document.body.appendChild(script);
  }

  render() {
    return (
      <div className="wrapper">
        <header className="s-header">
          <Header />
        </header>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          <Route exact path="microdao" element={<MicroDao />} />
          <Route exact path="microdao/new" element={<MicrodaoForm />} />
          <Route path="microdao/*" element={<MicrodaoCard />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <footer className="s-footer">
          <Footer />
        </footer>
      </div>
    );
  }
}

export default App;
