import Logo from '../pics/logo.svg';
import Youtube from '../pics/socials/youtube.svg';
import LinkedIn from '../pics/socials/in.svg';
import Github from '../pics/socials/github.svg';
import Telegram from '../pics/socials/telegram.svg';
import TelegramCN from '../pics/socials/cn-telegram.svg';
import Twitter from '../pics/socials/twitter.svg';
import Medium from '../pics/socials/m.svg';
import Facebook from '../pics/socials/facebook.svg';
import Instagram from '../pics/socials/insta.svg';
import Tiktok from '../pics/socials/tiktok.svg';
import Reddit from '../pics/socials/reddit.svg';
import Discord from '../pics/socials/discord.svg';
import DeWeb from '../pics/socials/dw-telegram.svg';
const scrollToTop = () =>{
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

const Footer = () => (
          <div className="container">
            <div className="row align-items-center">
              <div className="col-4 col-lg-3 order-1">
                <div className="s-logo" onClick={scrollToTop}><img src={ Logo } alt="" /></div>
              </div>
              <div className="col-12 col-lg-6 order-3 footer-contacts">
                <div className="links">Connect with us — <a href="mailto:info@envelop.is">info@envelop.is</a> • <a href="https://github.com/niftsy/niftsysmarts" target="_blank" rel="noreferrer">Github</a> • <a href="https://envelop.medium.com/" target="_blank" rel="noreferrer">Medium</a></div>
              </div>
              <div className="col-8 col-lg-8 order-2 footer-socials">
                <ul className="socials">
                  <li><a href="https://t.me/envelop_en" target="_blank" rel="noreferrer"><img src={ Telegram } alt="Envelop telegram group" /></a></li>
                  
                  <li><a href="https://www.linkedin.com/company/niftsy" target="_blank" rel="noreferrer"><img src={ LinkedIn } alt="NIFTSY is token" /></a></li>
                  <li><a href="https://t.me/envelop_cn" target="_blank" rel="noreferrer"><img src={ TelegramCN } alt="Envelop telegram group (China)" /></a></li>
                  <li><a href="https://t.me/envelop_rus" target="_blank" rel="noreferrer"><img src={ Telegram } alt="Envelop telegram group (Russia)" /></a></li>
                  <li><a href="https://envelop.medium.com/" target="_blank" rel="noreferrer"><img src={ Medium } alt="Blog about Web 3.0" /></a></li>
                  <li><a href="https://github.com/niftsy/niftsysmarts" target="_blank" rel="noreferrer"><img src={ Github } alt="Github of our NFT project" /></a></li>
                  <li><a href="https://www.youtube.com/c/ENVELOP" target="_blank" rel="noreferrer"><img src={ Youtube } alt="Envelop. NFTs YouTube Channel" /></a></li>
                  <li><a href="https://www.tiktok.com/@envelop.is" target="_blank" rel="noreferrer"><img src={ Tiktok } alt="Envelop TikTok Channel" /></a></li>
                  <li><a href="https://instagram.com/envelop.project" target="_blank" rel="noreferrer"><img src={ Instagram } alt="Instagram envelop.project" /></a></li>
                  <li><a href="https://www.reddit.com/r/ENVELOP_NIFTSY/" target="_blank" rel="noreferrer"><img src={ Reddit } alt="Envelop Reddit" /></a></li>
                  <li><a href="https://twitter.com/Envelop_project" target="_blank" rel="noreferrer"><img src={ Twitter }  alt="Our twitter"/></a></li>
                  <li><a href="https://discord.gg/gtYcjqq76f" target="_blank" rel="noreferrer"><img src={ Discord } alt="Envelop Discord group" /></a></li>
                  <li><a href="https://t.me/nonfungible_webf" target="_blank" rel="noreferrer"><img src={ DeWeb } alt="Envelop telegram group (DeWeb)" /></a></li>
                  <li><a href="https://www.facebook.com/profile.php?id=100089484795648" target="_blank" rel="noreferrer"><img src={ Facebook } alt="Envelop Facebook" /></a></li>
                </ul>
              </div>
            </div>
          </div>
);

export default Footer;