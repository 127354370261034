import { useScrollBoost } from 'react-scrollbooster';

const Roadmap = () => {

    const [viewport, scrollbooster] = useScrollBoost({
        scrollMode: 'transform',
        bounce: false,
        textSelection: false,
    });

	return (
        <section className="sec-roadmap" id="roadmap"><img className="sec-bg bg-left d-none d-lg-block" src="./pics/bg/bg-lg-left-3.svg" alt="" />
          <div className="container">
            <h2>Roadmap</h2>
            <div className="roadmap__help"><em>Drag or swipe the map to navigate</em></div>
            <div className="roadmap__slider">
              <div className="roadmap__slider-arrow"><img src="./pics/arrow-right.svg"  alt="" /></div>
              <div className="roadmap__slider-wrap" ref={viewport}>
                <div className="content">
                  <div className="l-area a-1"> <span>Blockchains</span></div>
                  <div className="l-area a-2"> <span>Functionality</span></div>
                  <div className="l-area a-3"> <span>Cases</span></div>
                  <div className="b-period empty"> 
                    <div className="b-area a-1"> </div>
                    <div className="b-area a-2"></div>
                    <div className="b-area a-3">   </div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Winter <b>2020</b></div>
                    <div className="b-area a-1">
                      <div className="b-area-content w-100"> 
                        <div className="inner"> <span className="blch"> <img src="./pics/icons/eth.svg" alt="" />Ethereum</span></div>
                      </div>
                    </div>
                    <div className="b-area a-2">
                      <div className="b-area-content w-125"> 
                        <div className="inner">DAO Envelop architecture: implemented the MVP.</div>
                      </div>
                    </div>
                    <div className="b-area a-3">
                      <div className="b-area-content w-150"> 
                        <div className="inner">Customer development. We managed to attract the first hundred members to the community, and attracted sponsorship from them for further development.                                       </div>
                      </div>
                    </div>
                    <div className="bp-title bottom">Winter <b>2020                                        </b></div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Spring <b>2021</b></div>
                    <div className="b-area a-1">
                      <div className="b-area-content w-100"> 
                        <div className="inner"> <span className="blch"> <img src="./pics/icons/bsc.svg" alt="" />BSC</span></div>
                      </div>
                    </div>
                    <div className="b-area a-2">
                      <div className="b-area-content w-150 ml-25"> 
                        <div className="inner">Verification of the Protocol model on an&nbsp;unbound Rarible case. Development of the alpha version of the Protocol and the Oracle prototype.</div>
                      </div>
                    </div>
                    <div className="b-area a-3">
                      <div className="b-area-content w-100 ml-50">  
                        <div className="inner">Cases: NFTstars, Oort Digital, etc.</div>
                      </div>
                    </div>
                    <div className="bp-title bottom">Spring <b>2021</b></div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Summer <b>2021</b></div>
                    <div className="b-area a-1">
                      <div className="b-area-content w-100"> 
                        <div className="inner"> <span className="blch"> <img src="./pics/icons/polygon.svg" alt="" />Polygon                                        </span></div>
                      </div>
                    </div>
                    <div className="b-area a-2">
                      <div className="b-area-content w-150 ml-75"> 
                        <div className="inner">Beta Protocol. wNFTs launchpad, farming (as a concession of the participation queue in micro-DAO), a plugin for the NFTstars marketplace, airdrop via wNFTs (or any other accrual)</div>
                      </div>
                    </div>
                    <div className="b-area a-3">
                      <div className="b-area-content w-150 ml-75"> 
                        <div className="inner">Development of Oracle's scoring and starts antifraud system</div>
                      </div>
                    </div>
                    <div className="bp-title bottom">Summer <b>2021</b></div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Autumn <b>2021</b></div>
                    <div className="b-area a-1">
                      <div className="b-area-content w-100"> 
                        <div className="inner"> <span className="blch"> <img src="./pics/icons/avalanche.svg" alt="" />Avalanche </span></div>
                      </div>
                    </div>
                    <div className="b-area a-2"></div>
                    <div className="b-area a-3"></div>
                    <div className="bp-title bottom">Autumn <b>2021</b></div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Winter <b>2021</b></div>
                    <div className="b-area a-1">
                      <div className="b-area-content w-100"> 
                        <div className="inner"> <span className="blch"> <img src="./pics/icons/one.svg" alt="" />Harmony </span></div>
                      </div>
                    </div>
                    <div className="b-area a-2">
                      <div className="b-area-content w-150 ml-50"> 
                        <div className="inner">Main App -  Front of version 1 application for end users. Oracle - Collection of ERC1155 end users data and reflection them on the front.</div>
                      </div>
                    </div>
                    <div className="b-area a-3"></div>
                    <div className="bp-title bottom">Winter <b>2021</b></div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Spring <b>2022</b></div>
                    <div className="b-area a-1">
                    <div className="b-area-content w-100"> 
                        <div className="inner"> <span className="blch"> <img src="./pics/icons/swarm.svg" alt="" />Swarm </span></div>
                      </div>
                    </div>
                    <div className="b-area a-2"></div>
                    <div className="b-area a-3"></div>
                    <div className="bp-title bottom">Spring <b>2022</b></div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Summer <b>2022</b></div>
                    <div className="b-area a-1">
                      <div className="b-area-content w-100"> 
                          <div className="inner"> <span className="blch"> <img src="./pics/icons/near.svg" alt="" />Near </span></div>
                        </div>
                    </div>
                    <div className="b-area a-2">
                      <div className="b-area-content w-150"> 
                        <div className="inner">Index - Launch of the Index primitive. Minting - Swarm node deployment and minting tokens with data storage in Swarm.</div>
                      </div>
                    </div>
                    <div className="b-area a-3">
                      <div className="b-area-content w-150"> 
                        <div className="inner">Cases - Releases of products with customized contracts implementations.</div>
                      </div>
                    </div>
                    <div className="bp-title bottom">Summer <b>2022</b></div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Autumn <b>2022</b></div>
                    <div className="b-area a-1">
                      <div className="b-area-content w-100"> 
                        <div className="inner"> <span className="blch"> <img src="./pics/icons/zilliqa.svg" alt="" />Zilliqa </span></div>
                      </div>
                    </div>
                    <div className="b-area a-2">
                      <div className="b-area-content w-150 ml-50"> 
                        <div className="inner"><strong>Dapps:</strong> New version of subscription service for SAFT; Deployment of wrapper on the Wax and Zilliqa blockchains; Development of NFT tickets platform; Concept development of new version of crosschain interactions with ZK-mechanics; MVP of DAO funds accounting service; MVP of Unstoppable NFT service; Backend for the new version of the Launchpad<br /><strong>Oracle:</strong> Run oracle on the Goerli test network<br /><strong>Protocol:</strong> Exchange MVP development</div>
                      </div>                      
                    </div>
                    <div className="b-area a-3"></div>
                    <div className="bp-title bottom">Autumn <b>2022</b></div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Winter <b>2022</b></div>
                    <div className="b-area a-1">
                      <div className="b-area-content w-100"> 
                        <div className="inner"> <span className="blch"> <img src="./pics/icons/wax.svg" alt="" />Wax </span></div>
                      </div>
                    </div>
                    <div className="b-area a-2"></div>
                    <div className="b-area a-3"></div>
                    <div className="bp-title bottom">Winter <b>2022</b></div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Spring <b>2023</b></div>
                    <div className="b-area a-1">
                    <div className="b-area-content w-75"> 
                        <div className="inner"> <span className="blch"> <img src="./pics/icons/arbitrum.svg" alt="" />Arbitrum</span></div>
                      </div>
                      <div className="b-area-content w-75 ml-75"> 
                        <div className="inner"> <span className="blch"> <img src="./pics/icons/zksync.svg" alt="" />zkSync</span></div>
                      </div>
                    </div>
                    <div className="b-area a-2">
                      <div className="b-area-content w-125"> 
                        <div className="inner">dApps UI/UХ improvement. MVP for p2p crypto exchange in EVM networks. Payment methods options in subscription services. New version of launchpad.</div>
                      </div>
                    </div>
                    <div className="b-area a-3">
                      <div className="b-area-content w-125"> 
                        <div className="inner">NFT tickets go-live for the first event. IDO Aggregator and wrapped NFT Aggregator releases.</div>
                      </div>
                    </div>
                    <div className="bp-title bottom">Spring <b>2023</b></div>
                  </div>
                  <div className="b-period"> 
                    <div className="bp-title top">Summer <b>2023</b></div>
                    <div className="b-area a-1"></div>
                    <div className="b-area a-2"></div>
                    <div className="b-area a-3"></div>
                    <div className="bp-title bottom">Summer <b>2023</b></div>
                  </div>
                  <div className="b-period empty"> 
                    <div className="b-area a-1"></div>
                    <div className="b-area a-2"></div>
                    <div className="b-area a-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
	);
}

export default Roadmap;
