import { Routes, Route, Link } from 'react-router-dom';
import ErrorPage from './error.component';

const MicrodaoCard = () => (
  <Routes>
    <Route path="scotch" element={
      <MicrodaoScotch />
    }
    />
    <Route path="*" element={
      <ErrorPage />
    }
    />
  </Routes>
);

function MicrodaoScotch() {
  return (
        <section class="sec-microdaointro" id="microdaocard">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <Link to={"/microdao"} class="back-link">
                  &larr; microDAO List
                </Link>
                <a className="btn btn-fixed" href="https://forms.gle/zZ2J6upKybJcx7J27" target="_blank" rel="noreferrer">Join Whitelist</a>
              </div>
            </div>
            <div class="row">
              <div class="microdaocard col-12 col-xl-10 offset-xl-2">
                  <div class="distrib__row header d-none d-lg-block">
                    <div class="row">
                      <div class="col-12 col-lg-3"></div>
                    </div>
                  </div>
                  <h2>SCOTCH MicroDAO</h2>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Title
                      </div>
                      <div class="col-12 col-lg-6">
                        <span class="sum">SCOTCH</span>
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Description
                      </div>
                      <div class="col-12 col-lg-6">
                        <span class="sum">Marketplace of wrapped liquidity.</span>
                        <p>SCOTCH is a specialized marketplace for selling wrapped liquidity using the Envelop protocol.</p>
                        <p>The marketplace functionality has many filters for a flexible search of digital assets contained in wrapped wNFTs, such as:</p>
                        <ul>
                        	<li>wNFT price filter</li>
                        	<li>wNFT unlock date filter (timelock)</li>
                        	<li>wNFT transfer fee filter (transfer Fee)</li>
                        	<li>filter by tokens contained inside wNFT (collateral)</li>
                        </ul>
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Target
                      </div>
                      <div class="col-12 col-lg-6">
                        <span class="sum">To Be Decided</span>
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Coins (chains)
                      </div>
                      <div class="col-12 col-lg-6">
                        DAI, USDT, USDC (Ethereum, BSC, Polygon)
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Development timeline
                      </div>
                      <div class="col-12 col-lg-6">
                        <span class="sum">December 2021 - March 2022 - MVP (done)</span>
                        <p>Q2 2022 - Ethereum connection, Avalanche, performance optimization</p>
                        <p>Q3 2022 - Integration with Envelop v1.0 protocol</p>
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Project goals
                      </div>
                      <div class="col-12 col-lg-6">
                        <p>Number of transactions:<br />
                        1st year - hundreds<br />
                        2nd year - thousands</p>
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Fundraise period
                      </div>
                      <div class="col-12 col-lg-6">
                        <span class="sum">6 months</span>
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Fundraise max limit
                      </div>
                      <div class="col-12 col-lg-6">
                        +10%
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Stakeholders share of income
                      </div>
                      <div class="col-12 col-lg-6">
                        <p>40% - Scotch<br />
                        40% - microDAO<br />
                        20% - Envelop</p>
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Payment term for holders
                      </div>
                      <div class="col-12 col-lg-6">
                        Not limited
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Holder share limit (max)
                      </div>
                      <div class="col-12 col-lg-6">
                        <span class="sum">To Be Decided</span>
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Additional features for participants
                      </div>
                      <div class="col-12 col-lg-6">
                        <p>Informing at least 3 venture funds and 5 startups about SCOTCH as a platform for OTC transactions.</p>
                        <p>Bonus/referral program, etc.</p>
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Platform
                      </div>
                      <div class="col-12 col-lg-6">
                        BSC, Polygon (possible implementation on Ethereum, Avalanche)
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Occupation
                      </div>
                      <div class="col-12 col-lg-6">
                        Developer
                      </div>
					</div>
                  </div>
                  <div class="distrib__row">
                    <div class="row">
                      <div class="col-12 col-lg-3">
                        Contacts
                      </div>
                      <div class="col-12 col-lg-6">
                        <p>Email: sxkote@gmail.com</p>
                        <p>Telegram: @litskevich</p>
                      </div>
					</div>
                  </div>
              </div>
            </div>
          </div>
        </section>
);
}

export default MicrodaoCard;