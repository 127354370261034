import Intro from './intro.component';
import Distrib from './distrib.component';
import Daostat from './daostat.component';
import Burnstat from './burnstat.component';
import WhereToBuy from './buy.component';
import Utilization from './utilization.component';
import Roadmap from './roadmap.component';
import Transparency from './transparency.component';

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const MainPage = () => (
        <main className="s-main">
          <Intro />
          <div className="divider left"></div>
          <Distrib />
          <Burnstat />
          <Daostat />
          <div className="divider right"></div>
          <Utilization />
          <Transparency />
          <div className="divider left"></div>
          <WhereToBuy />
          <div className="divider right"></div>
          <Roadmap />
        </main>
);

export default MainPage;