import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import config from '../config.json';

class Daostat extends React.Component {

  constructor() {
    super();
    this.state = {
        isActive: false,
        isLoaded: false,
      	data: [],
      	dataCurrent: [],
      	periodCurrent: '',
      	total: 0,
      	totalCurrent: 0,
      	activeClass: 0
    };
    // this.addActiveClass= this.addActiveClass.bind(this);
  }

  componentDidMount() {
    try {
      fetch(config.apiURL + "/dao/stat")
        .then((res) => res.json())
        .then((json) => {
        		if ('error' in json) {
        			throw new Error( json["error"] );
        		}
        		else {
	            this.setState({
	                data: json.reverse(),
	                isLoaded: true
	            });
	            this.countTotal();
        		}
        })
        .catch(error => {
        	console.log(error);
        });
    } catch(err) {
      console.log(err);
    }
  }

  countTotal = () => {
    if (this.state.data.length) {
      this.state.data.map(({total}) => {
        this.setState({
          total: this.state.total + total,
        });
        return true;
      });
      this.showTotal();
    }
  }

  showTotal = () => {
  	let data = this.state.data[0];
	this.setState({
	    dataCurrent: data.values,
      	periodCurrent: "Total spendings",
      	totalCurrent: this.state.total
	   });
  }

  showData = (m) => {
  	let data = this.state.data[m];
	this.setState({
	    dataCurrent: data.values,
      	periodCurrent: data.period,
      	totalCurrent: data.total
	   });
  }

  addActiveClass = (index) => {
    this.setState({activeClass: index});
  }

  numberWithSpaces = function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  render() {

	const margin = { top: 30, right: 200, bottom: 30, left: 30 };

	const styles = {
	  root: {
	    fontFamily: "consolas, sans-serif",
	    textAlign: "center",
	    position: "relative",
	    width: 600,
	    height: 400,
	    color: "#333"
	  },
	  overlay: {
	    position: "absolute",
	    top: 0,
	    right: margin.right,
	    bottom: 0,
	    left: margin.left,
	    display: "flex",
	    flexDirection: "column",
	    alignItems: "center",
	    justifyContent: "center",
	    fontSize: 48,
	    color: "#FFFFFF",
	    textAlign: "center",
	    pointerEvents: "none"
	  },
	  totalLabel: {
	    fontSize: 24
	  }
	};

	const theme = {
	  axis: {
	    fontSize: "10px",
	    tickColor: "#555555",
	    ticks: {
	      line: {
	        stroke: "#555555"
	      },
	      text: {
	        fill: "#333333"
	      }
	    },
	    legend: {
	      text: {
	        fill: "#aaaaaa"
	      }
	    }
	  },
	  grid: {
	    line: {
	      stroke: "#555555"
	    }
	  }
	};

	const legends = [
	  {
	    anchor: "right",
	    direction: "column",
	    justify: false,
	    translateX: 140,
	    translateY: 0,
	    itemsSpacing: 2,
	    itemWidth: 100,
	    itemHeight: 20,
	    itemDirection: "left-to-right",
	    itemOpacity: 0.85,
	    itemTextColor: "#ffffff",
	    symbolSize: 20,
	    effects: [
	      {
	        on: "hover",
	        style: {
	          itemOpacity: 1
	        }
	      }
	    ]
	  }
	];

    const MyResponsivePie = ({ data }) => (
      
      <ResponsivePie
          data={data}
          margin={margin}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          activeOuterRadiusOffset={8}
          innerRadius={0.75}
          padAngle={1}
          cornerRadius={5}
          animate={true}
          motionConfig="slow"
          transitionMode="startAngle"
          legends={legends}
          theme={theme}
      />
    );

    const { isLoaded, data, dataCurrent, periodCurrent, totalCurrent, activeClass } = this.state;

    return (
        <section className="sec-daostat" id="daostat">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-10 offset-xl-2">
                <h2>DAO spending statistics</h2>
                <div className="row"> 
		              {
		              	!isLoaded ? (
		              		<p>Loading..</p>
		              	) : (
		                  <div className="distrib__row header d-lg-block"> 
			                  <div className="row">
			                  	<div className="col-md-3 col-xs-12">
			                  		<ul className="daostat-links">
				                  		<li>
				                  			<a href="#total" className={activeClass === 0 ? "active" : "inactive"} onClick={ (e) => { e.preventDefault(); this.addActiveClass(0); this.showTotal() } }>Total spendings</a>
				                  		</li>
				                  		{ data.map((item, index) => {
					                  		var key = index+1;
					                  		return (
					                  			<li key={index}>
						                  			<a href={ "#" + item["period"].replace(/ /g, '').toLowerCase() } className={activeClass === key ? "active" : "inactive"} onClick={ (e) => { e.preventDefault(); this.addActiveClass( key ); this.showData( index ); } } >{ item["period"] }</a>
						                  		</li>
					                  		) })
				                  		}
			                  		</ul>
			                  	</div>
			                    <div className="col-md-9 col-xs-12">
														<div className="daostat-chart" style={styles.root}>
															<MyResponsivePie data={ dataCurrent } />
															<div className="daostat-overlay" style={styles.overlay}>
																<span>{ totalCurrent }</span>
																<span style={styles.totalLabel}> { periodCurrent }</span>
															</div>
														</div>
			                    </div>
			                  </div>
		                  </div>
		              	)
		              }		                  
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

export default Daostat;
