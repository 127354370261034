
const Buy = () => (
        <section className="sec-buy" id="buy"><img className="sec-bg bg-right d-none d-xl-block" src="./pics/bg/bg-lg-right-1.svg" alt="" />
          <div className="container">
            <h2>Where to buy NIFTSY Token </h2>
            <div className="sec-buy__item"> 
              <div className="row justify-content-start align-items-center">
                <div className="col-12 col-md-auto">
                  <div className="h2 text-grad"> <b>DEXs</b></div>
                </div>
                <div className="col-auto">           <a className="buy__logo logo-4" href="https://app.uniswap.org/#/pool/144069" target="_blank" rel="noreferrer"><img src="./pics/exchange/uniswap.svg" alt="app.uniswap.org" /></a></div>
                <div className="col-auto"><a className="buy__logo logo-5" href="https://info.quickswap.exchange/pair/0x67d40983fc25d34d788000f7d028f356f130b072" target="_blank" rel="noreferrer"><img src="./pics/exchange/quickswap.png" alt="info.quickswap.exchange" /></a></div>
                <div className="col-auto"><a className="buy__logo logo-6" href="https://pancakeswap.finance/info/pool/0xc40a60bf66f7718d2a058f648bcbbabd1ee08125" target="_blank" rel="noreferrer"><img src="./pics/exchange/pancake.svg" alt="pancakeswap.finance" /></a></div>
              </div>
            </div>
            <div className="sec-buy__item"> 
              <div className="row justify-content-start align-items-center">
                <div className="col-12 col-md-auto">
                  <div className="h2 text-grad"> <b>Launchpad</b></div>
                </div>
                <div className="col-auto"><a className="buy__logo logo-7" href="https://app.envelop.is/launchpad/" target="_blank" rel="noreferrer"><img src="./pics/logo.svg" alt="app.envelop.is" /></a></div>
              </div>
            </div>
          </div>
        </section>
  );

export default Buy;