import React from 'react';
import { Link } from 'react-router-dom';

class MicrodaoForm extends React.Component {

  constructor() {
    super();
    this.state = {
    	toggle: false,
    }
  }

  handleClick() {
  	this.state.toggle === false ? this.setState({ toggle: true }) : this.setState({ toggle: false });
  }

  render() {
  		return (
        <section className="sec-microdaoform" id="microdaoform">
          <div className="container">
            <div className="row"> 
              <div className="col-10 col-xl-10">
                <Link to={"/microdao"} class="back-link">
                  &larr; microDAO List
                </Link>
              	<h2>Create new microDAO</h2>
              	<div className="daoform" id="daoform"> 
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Title</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Description</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Target</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Coins (chains)</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Development timeline</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Project goals</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Fundraise period</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Fundraise max limit</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Stakeholders share of income</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Payment term for holders</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Holder share limit (max)</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Additional features for participants</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Platform</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Occupation</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	                <div className="distrib__row"> 
	                  <div className="row">
	                    <div className="col-12 col-lg-3">Contacts</div>
	                    <div className="col">
	                    	<input className="input-control" type="text" />
	                    </div>
	                  </div>
	                </div>
	              	<div className="col-10 col-xl-10 submit-wrapper">
	              		<a className="btn input-submit" href="#submit" target="_blank" rel="noreferrer" onClick={ () => this.handleClick() }>Submit</a>
	              	</div>
              	</div>
              </div>
            </div>
          </div>
        </section>
		);
  }
}

export default MicrodaoForm;