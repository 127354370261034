
const Transparency = () => (
        <section className="sec-buy" id="transparency"><img className="sec-bg bg-left d-none d-xl-block" src="./pics/bg/bg-lg-left-1.svg" alt="" />
          <div className="container">
            <div className="row"> 
              <div className="col-12 col-xl-10 offset-xl-2">
	            <h2>Transparency and Disclaimers</h2>
	            <div>
	              <p>The <strong>NIFTSY</strong> token is purely utilitarian and we describe as many of the cases as possible where it has been used and implemented, as well as plans for future implementations.</p>

	              <p>At the same time, nothing in materials is financial and/or other similar information, as it is understood in the classic world of financial advice: rather, it is a summary of the work done for DAO participants and external observers.</p>

	              <p>One should be aware that we work exclusively on open source solutions. Therefore, we cannot restrict access to them other than by direct vote of the DAO participants. Hence - a number of actions from legal, organizational and most importantly - technical point of view can be made only after the fact: when certain events occur, significant facts are discovered and/or some time coordinates are reached.</p>

	              <p>It should be also always kept in mind the main DAO disclaimers: <a href="http://docs.envelop.is/key-documents/white-paper/disclaimers" target="_blank" rel="noreferrer">docs.envelop.is/key-documents/white-paper/disclaimers</a>, because without their acceptance you will not be able to participate in the DAO, nor will you be able to receive full information from the DAO.</p>
	            </div>
              </div>
              <div className="col-12 col-xl-10 offset-xl-2">
              	<div class="list__row">
              		<div class="row">
              			<div class="col-12 col-lg-4">
              				<div className="pdf-icon">
              					<a href="./files/DAO_report_00_EN.pdf" target="_blank">DAO Report 2021<img src="./pics/icons/pdf.svg" alt="" /></a>
              				</div>
						</div>
						<div class="col-12 col-lg-4">
              				<div className="pdf-icon">
              					<a href="./files/DAO_Envelop_Locker_smart-contract.pdf" target="_blank">DAO Envelop Locker smart-contract<img src="./pics/icons/pdf.svg" alt="" /></a>
              				</div>
              			</div>
              		</div>
              	</div>
              </div>
            </div>
          </div>
        </section>
  );

export default Transparency;