import { Link } from 'react-router-dom';
import { ReactComponent as Daoimg } from '../pics/dao-img.svg';

const Intro = () => (
        <section className="sec-intro" id="intro">
          <div className="container">
            <div className="intro__img"><Daoimg alt="ENVELOP DAO" /></div>
            <div className="intro__text">
              <h1>Envelop <span className="text-grad">superDAO</span></h1>
              <h2 className="h1_sub subheader">Consists of Protocol, Oracle, Index, bound token</h2>
              <p>You can also become a part of superDAO as well as many different microDAOs that are implemented on our Protocol (Oracle, Index) and/or use our Token. To participate in microDAOs you need to deposit the liquidity, that will be swapped into NIFTSY tokens, requited for participation in the microDAO. You are also able to put your Tokens into farming, once waiting for the most appropriate microDAOs.</p>
              <div className="form-signup">
                <div className="row"> 
                  <div className="col-12 col-sm-6 mb-4 mb-sm-0">
                    <Link to={"/microdao"} className="btn">
                      MicroDAO List
                    </Link>
                  </div>
                  <div className="col-12 col-sm-4">
                    <Link to={"/microdao/new"} className="btn btn-outline">
                      + Create MicroDAO
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
);

export default Intro;