import Logo from '../pics/logo.svg';
import Youtube from '../pics/socials/youtube.svg';
import LinkedIn from '../pics/socials/in.svg';
import Github from '../pics/socials/github.svg';
import Telegram from '../pics/socials/telegram.svg';
import TelegramCN from '../pics/socials/cn-telegram.svg';
import Twitter from '../pics/socials/twitter.svg';
import Medium from '../pics/socials/m.svg';

const Header = () => (
        <div className="container"><a className="header__logo scroll-link" href="/#intro" rel="canonical"><img src={ Logo } alt="ENVELOP" /></a><a className="btn btn-app d-lg-none" href="http://app.envelop.is" target="_blank" rel="noreferrer">Protocol App</a>
          <div className="header__menu-toggle d-lg-none"><span></span><span></span><span></span></div>
          <div className="header__nav">
            <div className="order-lg-1">
              <ul className="header__menu">
                <li><a className="scroll-link" href="#distrib">Token distribution </a></li>
                <li><a className="scroll-link" href="#roadmap">Roadmap</a></li>
                <li><a href="https://docs.envelop.is/key-documents/white-paper" target="_block">Documentation   
                    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M7.95.75h3.11a.19.19 0 0 1 .19.19v3.1a.19.19 0 0 1-.32.14L9.77 3.02 6.96 5.83a.56.56 0 0 1-.8-.79l2.82-2.81-1.16-1.16a.19.19 0 0 1 .13-.32Zm-5.14.75A1.31 1.31 0 0 0 1.5 2.81V9.2c0 .72.59 1.31 1.31 1.31H9.2a1.31 1.31 0 0 0 1.31-1.31V6.56a.56.56 0 0 0-1.13 0V9.2a.19.19 0 0 1-.18.19H2.8a.19.19 0 0 1-.19-.2V2.82a.19.19 0 0 1 .2-.19h2.62a.56.56 0 1 0 0-1.12H2.8Z"></path>
                    </svg></a></li>
              </ul>
            </div><a className="btn btn-app d-none d-lg-flex order-lg-2" href="http://app.envelop.is" target="_blank" rel="noreferrer">Protocol App</a>
            <div className="order-lg-3">
              <ul className="socials">
                <li><a href="https://www.youtube.com/c/ENVELOP" target="_blank" rel="noreferrer"><img src={ Youtube } alt="Envelop. NFTs YouTube Channel" /></a></li>
                <li><a href="https://www.linkedin.com/company/niftsy" target="_blank" rel="noreferrer"><img src={ LinkedIn } alt="NIFTSY is token" /></a></li>
                <li><a href="https://github.com/niftsy/niftsysmarts" target="_blank" rel="noreferrer"><img src={ Github } alt="Github of our NFT project" /></a></li>
                <li><a href="https://t.me/envelop_en" target="_blank" rel="noreferrer"><img src={ Telegram } alt="Envelop telegram group" /></a></li>
                <li><a href="https://t.me/envelop_cn" target="_blank" rel="noreferrer"><img src={ TelegramCN } alt="Envelop telegram group (China)" /></a></li>
                <li><a href="https://twitter.com/Envelop_project" target="_blank" rel="noreferrer"><img src={ Twitter } alt="Our twitter" /></a></li>
                <li><a href="https://envelop.medium.com/" target="_blank" rel="noreferrer"><img src={ Medium } alt="Blog about Web 3.0" /></a></li>
              </ul>
            </div>
          </div>
        </div>
);

export default Header;