import React from 'react';
import icon_i_link  from '../pics/i-external-green-sm.svg';

class DistribRow extends React.Component {

  constructor() {
    super();
    this.state = {
      toggle: 0,
    }
  }

  handleClick(row) {
    this.state.toggle !== row ? this.setState({ toggle: row }) : this.setState({ toggle: 0 });
  }

  exLinkRender(link) {
    if(link) return icon_i_link;
    return null;
  }

  render() {
      const lockdate = this.props.lockdate;
      const schedule = this.props.schedule;
      const emitted = this.props.emitted;
      const locked = this.props.locked;
      const longterm = this.props.longterm;
      // get total 
      const total = emitted + locked;
      const ex_link = {
        'polygon': '',
        'eth': 'https://app.safe.global/balances?safe=eth:0xE0E4EC54ed883d7089895C0e951b4bB8E3c68e9d',
        'bsc': 'https://app.safe.global/balances?safe=bnb:0x721d86E0027c1c9E128c4f935AD80fBc921A9021'
      }
      return (
                <div className="distrib__row"> 
                  <div className="row">
                    <div className="col-12 col-lg-3"><a className="distrib__logo" href={ (ex_link[this.props.slug]) ? ex_link[this.props.slug] : '#' + this.props.slug } target={ (ex_link[this.props.slug]) ? '_blank' : '' }> <img src={`./pics/icons/${ this.props.slug }.svg`} alt="" /><span>{ this.props.title } <img className="i-ex" src={ this.exLinkRender(ex_link[this.props.slug]) } alt="" /></span></a></div>
                    <div className="col col-right"><span className="legend">Total supply</span><span className="sum">{ this.props.numberWithSpaces( total.toFixed(8) ) }</span></div>
                    <div className="col col-right"><span className="legend">Circulating</span><span className="sum">{ this.props.numberWithSpaces( emitted.toFixed(8) ) }</span></div>
                    <div className="col col-right"><span className="legend">Non-circulating</span><span className="sum distrib__schedule-toggle" onClick={ () => this.handleClick( this.props.id ) } >{ this.props.numberWithSpaces( locked.toFixed(8) ) }</span></div>
                    <div className="col-12 order-5">
                      { this.state.toggle === this.props.id &&
                      <div className="distrib__schedule">
                        <div className="row">
                          <div className="header"> 
                            <div className="title"></div>
                            <div className="lock-date">As of date<br className="d-none d-lg-block"/> { this.props.getCurrentDate("/") }</div>
                          </div>
                          <div className="schedule"> 
                            <div>Farming
                              <b>{ this.props.numberWithSpaces( longterm.toFixed(8) ) }</b>
                            </div>
                            {schedule
                              .map(({date, value},key) => (
                                <div key={key}>
                                  { this.props.monthFromDate(date) }<b>{ this.props.numberWithSpaces(value) }</b></div>
                              ))}
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                </div>
    );
  }
}

export default DistribRow;