import React from 'react';
import { ResponsiveStream } from '@nivo/stream';
import config from '../config.json';

class Burnstat extends React.Component {

  constructor() {
    super();
    this.state = {
        isLoaded: false,
      	dataCurrent: [],
    };
  }

  componentDidMount() {
    try {
        this.setState({
            dataCurrent: config.dataStream,
            isLoaded: true
        });
    } catch(err) {
      console.log(err);
    }
  }

  render() {

	const margin = { top: 30, right: 200, bottom: 30, left: 30 };

	const styles = {
	  root: {
	    fontFamily: "consolas, sans-serif",
	    textAlign: "center",
	    position: "relative",
	    width: 960,
	    height: 400,
	    color: "#333"
	  }
	};

	const theme = {
	  axis: {
	    fontSize: "10px",
	    tickColor: "#555555",
	    ticks: {
	      line: {
	        stroke: "#555555"
	      },
	      text: {
	        fill: "#aaaaaa"
	      }
	    },
	    legend: {
	      text: {
	        fill: "#aaaaaa"
	      }
	    }
	  },
	  grid: {
	    line: {
	      stroke: "#555555"
	    }
	  }
	};

	const legends = [
	  {
	    anchor: "right",
	    direction: "column",
	    justify: false,
	    translateX: 140,
	    translateY: 0,
	    itemsSpacing: 2,
	    itemWidth: 200,
	    itemHeight: 20,
	    itemDirection: "left-to-right",
	    itemOpacity: 0.85,
	    itemTextColor: "#ffffff",
	    symbolSize: 20,
	    effects: [
	      {
	        on: "hover",
	        style: {
	          itemOpacity: 1
	        }
	      }
	    ]
	  }
	];

    const MyResponsiveStream = ({ data }) => (
      
        <ResponsiveStream
            data={data}            
            keys={[
                'Family and Friends',
                'Project Pool'
            ]}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Total amount of NIFTSY tokens to be burn descending by quarter',
                legendOffset: 40
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: -40
            }}
            enableGridX={false}
            enableGridY={false}
            offsetType="diverging"
            colors={{ scheme: 'nivo' }}
            fillOpacity={0.85}
            borderColor={{ theme: 'background' }}
            curve="stepAfter"
            order="descending"
            isInteractive={true}
            animate={true}
            motionConfig="slow"
            transitionMode="startAngle"
            legends={legends}
            theme={theme}
        />
    );

    const { isLoaded, dataCurrent } = this.state;

    return (
        <section className="sec-burnstat" id="burnstat">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-10 offset-xl-2">
                <h2>Token Burn Plan</h2>
                <div className="roadmap__help"><em>Following is our Token Burn quarterly plan starting from Q1 2023</em></div>
                <div className="row"> 
		              {
		              	!isLoaded ? (
		              		<p>Loading..</p>
		              	) : (
		                  <div className="distrib__row header d-lg-block"> 
			                  <div className="row">
			                    <div className="col-md-12 col-xs-12">
									<div className="burnstat-chart" style={styles.root}>
										<MyResponsiveStream data={ dataCurrent } />
                                    </div>
			                    </div>
			                  </div>
		                  </div>
		              	)
		              }		                  
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

export default Burnstat;
